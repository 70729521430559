:root {
--primary: #11998e-;
--secondary: #38ef7d;
--white: #fff;
--gray: #9b9b9b;
--black: #161409;
}
.App {
  background-color: var(--black);
  width: 100%;
  overflow-x: hidden;
}

html {
  background-color: var(--black);
}

.protein-window {
  width: 100%;
  height: 85vh;
}
input {
  font-size: 1rem;
  width:100%;
  border:none;
  border-bottom: 2px solid #757575;
  background-color: var(--black);
  color: white;
  overflow-x: hidden;
}


button {
  font-size: 1rem;
  padding:0.5rem 0.5rem 0.5rem 0.5rem;
  border:none;
  border: 2px solid #757575;
  background-color: var(--black);
  color: white;
  overflow-x: hidden;
}

form {
  padding-left: 2vw;
  padding-right: 2vw;
}

.tool_tip {
  color: var(--gray) !important;
  font-size: 2rem;
  text-transform: capitalize;
}
